import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import styles from './LoginScreen.module.scss';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import Button from '../../common/components/button/Button';
import Input from '../../common/components/input/Input';
import Label from '../../common/components/label/Label';
import InputError from '../../common/components/inputError/InputError';
import FormItem from '../../common/components/formItem/FormItem';
import { Controller, useForm } from 'react-hook-form';
import AccountService from '../../api/account/AccountService';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../../store/authentication/action';
import Loading from '../../common/services/Loading';
import { Reducers } from '../../store/types';
import { FaChevronLeft } from 'react-icons/fa';
import { SelectValueLabel } from '../../common/types/SelectValueLabel';
import Select from '../../common/components/select/Select';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum ScreenType {
    LOGIN,
    RECOVER_PASSWORD,
    SET_PASSWORD,
}

type LoginForm = {
    username: string;
    password: string;
    domain: string;
}

type RecoverPasswordForm = {
    email: string;
}

type SetPasswordForm = {
    password: string;
    passwordRepeat: string;
}

const RecoverPasswordScreen: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { register: registerLogin, handleSubmit: handleSubmitLogin, errors: errorsLogin, control } = useForm<LoginForm>();
    const { register: registerRecoverPassword, handleSubmit: handleSubmitRecoverPassword, errors: errorsRecoverPassword } = useForm<RecoverPasswordForm>();
    const { register: registerSetPassword, handleSubmit: handleSubmitSetPassword, errors: errorsSetPassword, watch: watchSetPassword } = useForm<SetPasswordForm>();
    const { addToast } = useToasts()
    const dispatch = useDispatch();
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);
    const routeLocation = useLocation<any>();
    const query = useQuery();
    const queryToken = query.get('token');
    const queryEmail = query.get('email');
    const [screen, setScreen] = useState<ScreenType>(queryToken && queryEmail ? ScreenType.SET_PASSWORD : ScreenType.LOGIN);
    const [domainsOptions, setDomainsOptions] = useState<SelectValueLabel[]>([]);


    const getData = async () => {
        try {

            Loading.show();

            const [domains] = await Promise.all([
                AccountService.getDomains()
            ]);

            const __options = domains.map(x => ({ value: x, label: x }));
            __options.push({ value: 'LOCAL', label: t('login.local') })

            setDomainsOptions(__options);

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }

    }



    const onSubmitLogin = async ({ username, password, domain }: LoginForm) => {
        try {
            Loading.show();
            const response = await AccountService.login({ userName: username, password, domain });
            if (response) {
                dispatch(authenticateUser(response));
            }
            Loading.hide();
        } catch (error) {
            addToast(t('login.error_login'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitRecoverPassword = async ({ email }: RecoverPasswordForm) => {
        try {
            Loading.show();
            await AccountService.generateResetPasswordCode({ email: email });

            addToast(t('login.recover_password_email_sent'), { appearance: 'success' });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitSetPassword = async ({ password }: SetPasswordForm) => {
        try {
            if (!queryToken || !queryEmail || !password) {
                return;
            }

            Loading.show();
            await AccountService.resetPassword({ token: queryToken, email: queryEmail, password });

            addToast(t('login.password_reset_success'), { appearance: 'success' });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, [])

    if (isAuthenticated && screen != ScreenType.SET_PASSWORD) {
        return <Redirect to={routeLocation?.state?.from || '/'} />
    }
    return (
        <ScreenTitle title={t('login.title')}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <h1>{t('app.name')}</h1>
                        <h2>{t('login.welcome')}</h2>
                        {(screen === ScreenType.RECOVER_PASSWORD || screen === ScreenType.LOGIN) && <a
                            className={`${styles.recoverPasswordLink} ${screen === ScreenType.RECOVER_PASSWORD ? styles.disabled : ''}`}
                            onClick={() => setScreen(ScreenType.RECOVER_PASSWORD)}
                        >
                            {t('login.recover_password_link')}
                        </a>}
                        {(screen === ScreenType.SET_PASSWORD) && <a
                            className={styles.recoverPasswordLink}
                            onClick={() => setScreen(ScreenType.LOGIN)}
                        >
                            {t('login.title')}
                        </a>}
                    </div>
                    <div className={styles.middle}>
                        {screen === ScreenType.LOGIN && <form onSubmit={handleSubmitLogin(onSubmitLogin)}>
                            <h1>{t('login.title')}</h1>

                            <FormItem className={styles.firstItem}>
                                <Label>{t('login.username')}</Label>
                                <Input preset="login" name="username" placeholder={t('login.username')} ref={registerLogin({ required: true })} />


                                <InputError error={errorsLogin.username} />
                            </FormItem>

                            <FormItem>
                                <Label>{t('login.password')}</Label>
                                <Input preset="login" name="password" type="password" placeholder={t('login.password')} ref={registerLogin({ required: true })} />
                                <InputError error={errorsLogin.password} />
                            </FormItem>

                            <FormItem>
                                 <Label>{t('login.domain')}</Label>
                              {domainsOptions && domainsOptions[0] && <Controller
                                    render={({ onChange, value }) => {
                                        return (
                                            <Select      
                                                className={ styles.selectLogin}
                                                isDisabled={false}
                                                options={domainsOptions}
                                                placeholder={t('login.domain')}
                                                onChange={(data: SelectValueLabel) => {
                                                    onChange(data.value);
                                                }}
                                                value={domainsOptions.find(x => x.value === value)}
                                                filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                            />
                                        );
                                    }}
                                    control={control}
                                    name="domain"
                                    defaultValue={domainsOptions[0].value}
                                /> }
                                <InputError error={errorsLogin.domain} />
                            </FormItem>





                            <Button type="submit" text={t('login.login_button')} />
                        </form>}
                        {screen === ScreenType.RECOVER_PASSWORD && <form onSubmit={handleSubmitRecoverPassword(onSubmitRecoverPassword)}>
                            <div className={styles.goBackButton} onClick={() => setScreen(ScreenType.LOGIN)}>
                                <div className={styles.goBackButtonIcon}>
                                    <FaChevronLeft size={16} />
                                </div>
                                <span>{t('login.go_back')}</span>
                            </div>

                            <h1>{t('login.recover_password_title')}</h1>
                            <h2>{t('login.recover_password_subtitle')}</h2>

                            <FormItem className={styles.firstItem}>
                                <Label>{t('login.email')}</Label>
                                <Input preset="login" name="email" placeholder={t('login.email')} ref={registerRecoverPassword({ required: true })} />
                                <InputError error={errorsRecoverPassword.email} />
                            </FormItem>

                            <Button type="submit" text={t('login.recover_password_button')} />
                        </form>}
                        {screen === ScreenType.SET_PASSWORD && <form onSubmit={handleSubmitSetPassword(onSubmitSetPassword)}>
                            <div className={styles.goBackButton} onClick={() => setScreen(ScreenType.LOGIN)}>
                                <div className={styles.goBackButtonIcon}>
                                    <FaChevronLeft size={16} />
                                </div>
                                <span>{t('login.go_back')}</span>
                            </div>

                            <h1 style={{ marginTop: '2rem' }}>{t('login.set_password_title')}</h1>
                            <h2>{t('login.set_password_subtitle')}</h2>

                            <FormItem className={styles.firstItem}>
                                <Label>{t('login.password')}</Label>
                                <Input preset="login" type="password" name="password" minLength={5} placeholder={t('login.password')} ref={registerSetPassword({ required: true, minLength: 5 })} />
                                <InputError error={errorsSetPassword.password} />
                            </FormItem>
                            <FormItem>
                                <Label>{t('login.password_repeat')}</Label>
                                <Input preset="login" type="password" name="passwordRepeat" placeholder={t('login.password_repeat')}
                                    ref={registerSetPassword({ required: true, validate: value => value === watchSetPassword('password') || t('common.errors.password_not_match') })} />
                                <InputError error={errorsSetPassword.passwordRepeat} />
                            </FormItem>

                            <Button type="submit" text={t('login.change_password_button')} />
                        </form>}
                    </div>
                    <div className={styles.right}></div>
                </div>
            </div>
        </ScreenTitle>
    );
}

export default RecoverPasswordScreen;
