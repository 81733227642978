import React, { useState } from 'react';
import ListCriticalitiesScreen from '../../criticalities/list/ListCriticalitiesScreen';
import CriticalitiesScreen from '../../criticalities/CriticalitiesScreen';
import { CriticalityViewModel } from '../../../api/criticalities/models/CriticalityViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_CRITICALITIES,
    CRITICALITY
}

const ParametersCriticalitiesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {
    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_CRITICALITIES);
    const [currentCriticality, setCurrentCriticality] = useState<CriticalityViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_CRITICALITIES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.CRITICALITIES}/> }
            { screen === ScreenType.LIST_CRITICALITIES && <ListCriticalitiesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewCriticality={() => {
                    setIsDetails(false);
                    setCurrentCriticality(null);
                    setScreen(ScreenType.CRITICALITY)
                }}
                onClickItem={(item: CriticalityViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentCriticality(item);
                        setScreen(ScreenType.CRITICALITY);
                    }
                }}
            ></ListCriticalitiesScreen>}
            { screen === ScreenType.CRITICALITY && <CriticalitiesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                criticalityId={currentCriticality?.id}
                afterSave={() => {
                    setCurrentCriticality(null);
                    setScreen(ScreenType.LIST_CRITICALITIES);
                }}></CriticalitiesScreen>}
        </div>

    );
}

export default ParametersCriticalitiesScreen;
