import { ProjectForm, ProjectFormConcernedEstablishment, ProjectScreenMode } from '../models/ProjectForm';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { DateMode, ProjectAllocationTotalsViewModel, ProjectAllocationTypeDateViewModel, ProjectAllocationTypeEstablishmentPoleViewModel, ProjectAllocationTypeEstablishmentViewModel, ProjectAllocationTypeViewModel, ProjectEvaluationMethodologyViewModel, ProjectInvestmentTotalsViewModel, ProjectInvestmentTypeDateViewModel, ProjectInvestmentTypeEstablishmentPoleViewModel, ProjectInvestmentTypeEstablishmentViewModel, ProjectInvestmentTypeViewModel, ProjectPhaseDatePeriodItemViewModel, ProjectPhaseDatePeriodProvisionalScheduleViewModel, ProjectPhaseDateViewModel, ProjectPhaseViewModel, ProjectQualityQuestionViewModel, ProjectScoreViewModel, ProjectSuccessIndicatorViewModel, ProjectViewModel } from '../../../api/projects/models/ProjectViewModel';
import { UserProfile } from '../../../api/account/models/UserProfile';
import { IdNameWithChildsDto } from '../../../api/common/models/IdNameDto';
import { DepartmentViewModel } from '../../../api/departments/models/DepartmentViewModel';
import { Page } from '../../../api/common/types/Page';
import { ProjectTypeViewModel } from '../../../api/projectTypes/models/ProjectTypeViewModel';
import { InvestmentTypeDto } from '../../../api/investmentTypes/models/InvestmentTypeDto';
import dayjs from 'dayjs';
import moment from 'moment';
import { InternalAllocationTypeDto } from '../../../api/internalAllocationTypes/models/InternalAllocationTypeDto';
import Utils from '../../../common/services/Utils';
import { ScoreViewModel } from '../../../api/scores/models/ScoreViewModel';
import { QualityQuestionViewModel } from '../../../api/qualityQuestions/models/QualityQuestionViewModel';
import { PartnersViewModel } from '../../../api/partners/models/PartnersViewModel';
import { ScoreType } from '../../../api/scores/enums/ScoreType';
import { ProjectResponsibleDsiViewModel } from '../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';

const DEFAULT_INVESTMENTS_DATE_YEARS = 5;

class ProjectScreenFormBuilder {

    private form: UseFormMethods<ProjectForm>;

    constructor(form: UseFormMethods<ProjectForm>) {
        this.form = form;
    }

    public buildProject(project: ProjectViewModel | null, user: UserProfile | null, projectType: ProjectTypeViewModel,
        successIndicators: UseFieldArrayMethods<ProjectSuccessIndicatorViewModel, 'id'>,
        evaluationMethodologies: UseFieldArrayMethods<ProjectEvaluationMethodologyViewModel, 'id'>
    ) {
        if (project) {
            this.form.setValue('id', project.id);
            this.form.setValue('identification', project.identification);
            this.form.setValue('designation', project.designation);
            this.form.setValue('projectVersion', project.projectVersion);
            this.buildUpdatedDate(project, !!project.updatedDate ? new Date(project.updatedDate) : null);
            this.form.setValue('priorityLevelId', project.priorityLevelId);
            this.form.setValue('criticalityId', project.criticalityId);
            this.form.setValue('authorId', project.authorId);
            this.form.setValue('domainId', project.domainId);
            this.form.setValue('strategicAxes', project.strategicAxes);
            this.form.setValue('products', project.products);
            this.form.setValue('selectAllConcernedEstablishments', project.selectAllConcernedEstablishments);
            this.form.setValue('startDate', new Date(project.startDate));
            this.form.setValue('endDate', new Date(project.endDate));
            this.form.setValue('successIndicators', project.successIndicators);
            this.form.setValue('evaluationMethodologies', project.evaluationMethodologies);
            this.form.setValue('statusId', project.statusId);
            this.form.setValue('statusName', project.statusName);
            this.form.setValue('deadlines', project.deadlines);
            this.form.setValue('relatedProjects', project.relatedProjects);
            this.form.setValue('sourceId', project.sourceId);
            this.form.setValue('investimentsUnitId', project.investimentsUnitId);
            this.form.setValue('investimentsUnit', project.investimentsUnit);
            this.form.setValue('internalAllocationsUnitId', project.internalAllocationsUnitId);
            this.form.setValue('internalAllocationsUnit', project.internalAllocationsUnit);
            this.form.setValue('projectManagerId', project.projectManagerId);
            this.form.setValue('responsibles', project.responsibles);
            this.form.setValue('implementationMethods', project.implementationMethods);
            this.form.setValue('observationsImpactsOrganization', project.observationsImpactsOrganization);
            this.form.setValue('technologiesSolutionsDescription', project.technologiesSolutionsDescription);
            this.form.setValue('scores', project.scores);
            this.form.setValue('totalOperatingInvestments', project.totalOperatingInvestments || 0);
            this.form.setValue('totalOperatingInvestmentsComment', project.totalOperatingInvestmentsComment);
            this.form.setValue('totalCostComment', project.totalCostComment);
            this.form.setValue('subventions', project.subventions);
            this.form.setValue('budgetings', project.budgetings);
            this.form.setValue('contextDiagnosis', project.contextDiagnosis);
            this.form.setValue('projectObjectivesAndChallenges', project.projectObjectivesAndChallenges);
            this.form.setValue('targetPopulations', project.targetPopulations);
            this.form.setValue('expectedFeatures', project.expectedFeatures);
            this.form.setValue('risksOfNotDoing', project.risksOfNotDoing);
            this.form.setValue('vigilancePoints', project.vigilancePoints);
            this.form.setValue('typologyId', project.typologyId);
            this.form.setValue('responsiblesDsi', project.responsiblesDsi);
            this.form.setValue('riskLevel', project.riskLevel);
            this.form.setValue('riskDescription', project.riskDescription);

            if (project.successIndicators.length == 0) {
                successIndicators.append({ successIndicatorId: '00000000-0000-0000-0000-000000000000' });
            }
            if (project.evaluationMethodologies.length == 0) {
                evaluationMethodologies.append({ evaluationMethodologyId: '00000000-0000-0000-0000-000000000000' });
            }

        } else {
            this.form.setValue('groupId', user?.groupId);
            this.form.setValue('investimentsUnitId', projectType.investimentsUnitId);
            this.form.setValue('investimentsUnit', projectType.investimentsUnit);
            this.form.setValue('internalAllocationsUnitId', projectType.internalAllocationsUnitId);
            this.form.setValue('internalAllocationsUnit', projectType.internalAllocationsUnit);

            successIndicators.append({ successIndicatorId: '00000000-0000-0000-0000-000000000000' });
            evaluationMethodologies.append({ evaluationMethodologyId: '00000000-0000-0000-0000-000000000000' });
        }
    }

    public buildProjectAfterUpdate(project: ProjectViewModel | null, id: string, date: Date | null) {
        if (project) {
            if (!!id) {
                this.form.setValue('id', id);
            }
            this.buildUpdatedDate(project, date);
        }
    }

    public buildUpdatedDate(project: ProjectViewModel | null, date: Date | null) {
        if (project) {
            this.form.setValue('updatedDate', date);
        }
    }

    public buildConcernedEstablishments(establishments: IdNameWithChildsDto[], project: ProjectViewModel | null) {
        const formConcernedEstablishments: ProjectFormConcernedEstablishment[] = [];
        
        establishments.forEach(ce => {
            const ceInModel = project?.concernedEstablishments.find(x => x.establishmentId === ce.id);
            if(ce.active || (!ce.active && ceInModel))
            {
                formConcernedEstablishments.push({
                    establishmentId: ce.id,
                    name: ce.name,
                    active: ce.active,
                    checked: ceInModel ? true : false,
                    poles: ce.childs?.map(p => ({
                        name: p.name,
                        active: ce.active,
                        poleId: p.id,
                        checked: ceInModel?.poles?.find(x => x.poleId === p.id) ? true : false,
                    }))
                })
            }
        });

        this.form.setValue('concernedEstablishments', formConcernedEstablishments);
    }

    public buildDepartmentsInvolved(departments: Page<DepartmentViewModel>, project: ProjectViewModel | null) {
        this.form.setValue('departmentsInvolved', departments.items.map(x => {
            return {
                departmentId: x.id,
                name: x.name,
                active: x.active,
                checked: !!project && !!project.departmentsInvolved && !!project.departmentsInvolved.find(z => z.departmentId == x.id ?? 0)
            }
        }));
    }

    public buildInvestmentsTypesAndTotals(rawInvestmentTypes: InvestmentTypeDto[], project: ProjectViewModel | null, mode: ProjectScreenMode, auxDateMode: string, auxDateFormat: string, setAuxDateFormat: (dateFormat: string) => void, setAuxDateMode: (dateMode: string) => void, setAuxInvestmentTypes: (list: InvestmentTypeDto[]) => void) {
        if (project && (mode == 'details' || mode == 'edit' || mode == 'copy')) {

            if (project.investmentTotals && project.investmentTotals.length > 0) {
                if (project.investmentTotals[0].dateFormat) {
                    setAuxDateFormat(project.investmentTotals[0].dateFormat);
                }
                if (project.investmentTotals[0].dateMode) {
                    setAuxDateMode(project.investmentTotals[0].dateMode);
                }
            }

            setAuxInvestmentTypes(!!project.investmentTypes ? project.investmentTypes.map(x => ({ id: x.investmentTypeId, name: x.name, iconSvg: x.iconSvg })) : []);

            const formInvestmentTypes: ProjectInvestmentTypeViewModel[] = project.investmentTypes.map(it => {

                const itDates: ProjectInvestmentTypeDateViewModel[] = it.dates.map(itd => {
                    return {
                        total: itd.total,
                        date: itd.date,
                    }
                });

                const itEstablishments: ProjectInvestmentTypeEstablishmentViewModel[] = it.establishments.map(e => {
                    const ePoles: ProjectInvestmentTypeEstablishmentPoleViewModel[] = e.poles?.map(p => {
                        return {
                            percentage: p.percentage,
                            poleId: p.poleId,
                            poleName: p.poleName,
                        };
                    }) ?? [];

                    return {
                        percentage: e.percentage,
                        establishmentId: e.establishmentId,
                        establishmentName: e.establishmentName,
                        poles: ePoles,
                        total: e.total,
                    };
                });

                return {
                    dates: itDates,
                    iconSvg: it.iconSvg,
                    investmentTypeId: it.investmentTypeId,
                    name: it.name,
                    establishments: itEstablishments,
                    totalComment: it.totalComment,
                };
            });

            this.form.setValue('investmentTypes', formInvestmentTypes);
            this.form.setValue('investmentTotals', project.investmentTotals);
        }
        else {
            const now = new Date();
            now.setDate(1);
            const end = new Date();
            end.setDate(1);
            end.setFullYear(now.getFullYear() + DEFAULT_INVESTMENTS_DATE_YEARS);

            this.form.setValue('startDate', now);
            this.form.setValue('endDate', end);

            this.buildInvestmentsTypesAndTotalsByDates(rawInvestmentTypes, auxDateMode, auxDateFormat);
        }
    }

    private getNextDate(date: Date, auxDateMode: string) {
        if (auxDateMode == DateMode.YEAR) {
            return dayjs(date).add(1, 'year');
        }
        if (auxDateMode == DateMode.MONTH) {
            return dayjs(date).add(1, 'M');
        }
        return dayjs(date).add(1, 'd');
    }

    private generateDatesArray(dates: Date[], date: Date, endDate: Date, auxDateMode: string) {
        if (dayjs(date) < dayjs(endDate) || dayjs(date).format('DD-MM-YYYY') == dayjs(endDate).format('DD-MM-YYYY')) {
            dates.push(dayjs(date).toDate());
            this.generateDatesArray(dates, this.getNextDate(date, auxDateMode).toDate(), endDate, auxDateMode);
        }
    }

    public buildInvestmentsTypesAndTotalsByDates(rawInvestmentTypes: InvestmentTypeDto[], auxDateMode: string, auxDateFormat: string) {

        const formStartDate = this.form.watch('startDate');
        const formEndDate = this.form.watch('endDate');
        const formSelectAllEstablishements = this.form.watch('selectAllConcernedEstablishments');
        const formConcernedEstablishments = this.form.watch('concernedEstablishments');
        const formInvestmentTypes = this.form.watch('investmentTypes');
        const formDates = formInvestmentTypes.map(x => x.dates).flat();
        const formTotals = this.form.watch('investmentTotals');

        const dates: Date[] = Utils.getYearsInRange(formStartDate, formEndDate);

        const listInvestmentTypes: ProjectInvestmentTypeViewModel[] = (rawInvestmentTypes).map(it => {

            const oldInvestmentType = formInvestmentTypes?.find(x => x.investmentTypeId === it.id);

            const itDates: ProjectInvestmentTypeDateViewModel[] = dates.map(d => {
                const findD = oldInvestmentType?.dates.filter(x => dayjs(x.date).format('YYYY') == dayjs(d).format('YYYY'));
                return {
                    total: findD && findD.length > 0 ? findD.map(x => x.total).reduce((sum, x) => sum + x) : 0,
                    date: d
                }
            });

            const itEstablishments: ProjectInvestmentTypeEstablishmentViewModel[] = (
                formSelectAllEstablishements ? [] : formConcernedEstablishments.filter(x => x.checked)
            )
                .map(e => {

                    const oldEstablishment = oldInvestmentType?.establishments.find(x => x.establishmentId == e.establishmentId);

                    const ePoles: ProjectInvestmentTypeEstablishmentPoleViewModel[] = (e.poles?.filter(x => x.checked) || [])
                        .map(p => {
                            const oldPole = oldEstablishment ? oldEstablishment.poles.find(x => x.poleId == p.poleId) : null;

                            return {
                                percentage: oldPole ? oldPole.percentage : 0,
                                poleId: p.poleId,
                                poleName: p.name,
                            };
                        }) ?? [];


                    return {
                        percentage: oldEstablishment ? oldEstablishment.percentage : 0,
                        establishmentId: e.establishmentId,
                        establishmentName: e.name,
                        poles: ePoles,
                        total: oldEstablishment ? oldEstablishment.total : 0,
                    };
                });

            return {
                dates: itDates,
                iconSvg: it.iconSvg,
                name: it.name,
                establishments: itEstablishments,
                investmentTypeId: it.id || '',
                totalComment: oldInvestmentType?.totalComment || '',
            };

        });
        this.form.setValue('investmentTypes', []);
        this.form.setValue('investmentTypes', listInvestmentTypes);

        const datesInvestimentTotals: ProjectInvestmentTotalsViewModel[] = dates.map(d => {
            const findT = formTotals.find(x => moment(x.date).format('MM/YYYY') == moment(d).format('MM/YYYY'));
            return {
                date: d,
                dateMode: findT ? findT.dateMode : auxDateMode,
                dateFormat: findT ? findT.dateFormat : auxDateFormat,
                total: findT ? findT.total : 0,
                report: findT ? findT.report : 0,
                totalFinal: findT ? findT.totalFinal : 0,
                comment: findT ? findT.comment : undefined
            }
        });
        this.form.setValue('investmentTotals', datesInvestimentTotals);

    }

    public buildInternalAllocationTypes(rawInternalAllocationTypes: InternalAllocationTypeDto[], project: ProjectViewModel | null, mode: ProjectScreenMode, auxDateMode: string, auxDateFormat: string, setAuxInternalAllocationTypes: (list: InternalAllocationTypeDto[]) => void) {

        if (project && (mode == 'details' || mode == 'edit' || mode == 'copy')) {

            setAuxInternalAllocationTypes(!!project.internalAllocationTypes ? project.internalAllocationTypes.map(x => ({ id: x.internalAllocationTypeId, name: x.name, iconSvg: x.iconSvg, dateFormat: auxDateFormat, dateMode: auxDateMode })) : []);

            const formInternalAllocationTypes = project.internalAllocationTypes.map(it => {
                const itDates: ProjectAllocationTypeDateViewModel[] = it.dates.map(itd => {
                    return {
                        total: itd.total,
                        date: itd.date,
                    }
                });

                const itEstablishments: ProjectAllocationTypeEstablishmentViewModel[] = it.establishments.map(e => {
                    const ePoles: ProjectAllocationTypeEstablishmentPoleViewModel[] = e.poles?.map(p => {
                        return {
                            percentage: p.percentage,
                            poleId: p.poleId,
                            poleName: p.poleName,
                        };
                    }) ?? [];

                    return {
                        percentage: e.percentage,
                        establishmentId: e.establishmentId,
                        establishmentName: e.establishmentName,
                        poles: ePoles,
                        total: e.total,
                    };
                });

                return {
                    dates: itDates,
                    iconSvg: it.iconSvg,
                    name: it.name,
                    internalAllocationTypeId: it.internalAllocationTypeId,
                    establishments: itEstablishments,
                };
            });

            this.form.setValue('internalAllocationTypes', formInternalAllocationTypes);
            this.form.setValue('internalAllocationTotals', project.internalAllocationTotals);
        }
        else {
            this.buildInternalAllocationTypesByDates(rawInternalAllocationTypes, auxDateMode, auxDateFormat);
        }
    }

    public buildInternalAllocationTypesByDates(rawInternalAllocationTypes: InternalAllocationTypeDto[], auxDateMode: string, auxDateFormat: string) {

        const formStartDate = this.form.watch('startDate');
        const formEndDate = this.form.watch('endDate');
        const formSelectAllEstablishements = this.form.watch('selectAllConcernedEstablishments');
        const formConcernedEstablishments = this.form.watch('concernedEstablishments');
        const formInternalAllocationTypes = this.form.watch('internalAllocationTypes');
        const formDates = formInternalAllocationTypes.map(x => x.dates).flat();
        const formTotals = this.form.watch('internalAllocationTotals');

        const dates: Date[] = Utils.getYearsInRange(formStartDate, formEndDate);
        //const dates: Date[] = [dayjs(formStartDate).toDate()];
        //if (formEndDate) {
        //    this.generateDatesArray(dates, this.getNextDate(formStartDate, auxDateMode).toDate(), formEndDate, auxDateMode);
        //}

        const listInternalAllocationTypes: ProjectAllocationTypeViewModel[] = (rawInternalAllocationTypes).map(it => {

            const oldAllocationType = formInternalAllocationTypes?.find(x => x.internalAllocationTypeId === it.id);

            const itDates: ProjectAllocationTypeDateViewModel[] = dates.map(d => {
                const findD = formDates.find(x => x.date == d);
                return {
                    total: findD ? findD.total : 0,
                    date: d,
                }
            });

            const itEstablishments: ProjectAllocationTypeEstablishmentViewModel[] = (
                formSelectAllEstablishements ? [] : formConcernedEstablishments.filter(x => x.checked)
            ).map(e => {

                const oldEstablishment = oldAllocationType?.establishments.find(x => x.establishmentId == e.establishmentId);

                const ePoles: ProjectAllocationTypeEstablishmentPoleViewModel[] = (e.poles?.filter(x => x.checked) || [])
                    .map(p => {
                        const oldPole = oldEstablishment ? oldEstablishment.poles.find(x => x.poleId == p.poleId) : null;
                        return {
                            percentage: oldPole ? oldPole.percentage : 0,
                            poleId: p.poleId,
                            poleName: p.name,
                        };
                    }) ?? [];

                return {
                    percentage: oldEstablishment ? oldEstablishment.percentage : 0,
                    establishmentId: e.establishmentId,
                    establishmentName: e.name,
                    poles: ePoles,
                    total: oldEstablishment ? oldEstablishment.total : 0,
                };
            });

            return {
                dates: itDates,
                iconSvg: it.iconSvg || '',
                internalAllocationTypeId: it.id || '',
                name: it.name,
                establishments: itEstablishments,
            };

        });

        this.form.setValue('internalAllocationTypes', []);
        this.form.setValue('internalAllocationTypes', listInternalAllocationTypes);

        const datesAllocationTotals: ProjectAllocationTotalsViewModel[] = dates.map(d => {
            const findT = formTotals.find(x => moment(x.date).format('MM/YYYY') == moment(d).format('MM/YYYY'));
            return {
                date: d,
                dateMode: findT ? findT.dateMode : auxDateMode,
                dateFormat: findT ? findT.dateFormat : auxDateFormat,
                total: findT ? findT.total : 0,
            }
        });
        this.form.setValue('internalAllocationTotals', datesAllocationTotals);
    }

    public buildPhases(project: ProjectViewModel | null) {
        const DSIResponsibles = project?.responsiblesDsi.sort((a, b) => (a.name > b.name) ? 1 : -1) ?? [];

        const phases: ProjectPhaseViewModel[] = (project?.phases || []).map(phase => {
            const dates: ProjectPhaseDateViewModel[] = phase.dates.map(date => {
                const checkedMonths = (date.periods || []).map(x => Utils.getMonthsInRange(new Date(x.dateStart), new Date(x.dateEnd))).flat();
                const yearMonths = Utils.getMonthsInYear(new Date(date.date));
                let provisional: ProjectPhaseDatePeriodProvisionalScheduleViewModel[];
                if (date.periods) {
                    provisional = date.periods.map(x => x.provisionalSchedules ?? []).flat();
                }

                const periodsItems: ProjectPhaseDatePeriodItemViewModel[] = yearMonths.map(yearMonth => {
                    const schedules: ProjectPhaseDatePeriodProvisionalScheduleViewModel[] = [];
                    DSIResponsibles.forEach(dsi => {

                        const aux = provisional.find(p => {
                            const provisionalDate = new Date(p.date).toLocaleDateString([], { year: 'numeric', month: '2-digit' });
                            const yearMonthDate = new Date(yearMonth).toLocaleDateString([], { year: 'numeric', month: '2-digit' });
                            return (provisionalDate == yearMonthDate && p.projectResponsibleDsiId == dsi.projectResponsibleDsiId)
                        })

                        schedules.push({
                            projectResponsibleDsiId: dsi.projectResponsibleDsiId,
                            projectResponsibleDsiName: dsi.name,
                            date:  new Date(aux?.date ?? yearMonth),
                            estimatedTime: aux?.estimatedTime
                        });
                    })
                    return {
                        checked: checkedMonths.find(x => x.getMonth() === yearMonth.getMonth()) ? true : false,
                        date: yearMonth,
                        provisionalSchedules: schedules ?? []
                    };
                });

                return {
                    ...date,
                    periods:[],
                    date: new Date(date.date),
                    id: undefined,
                    periodsItems,
                };
            });
            return {
                guid: phase.guid,
                name: phase.name,
                order: phase.order,
                percentageAchievement: phase.percentageAchievement,
                dates,
            }
        });

        this.form.setValue('phases', []);
        this.form.setValue('phases', phases);
    }

    public copyPhases() {
        const phases: ProjectPhaseViewModel[] = (this.form.getValues('phases') || []).map(phase => {
            return {
                ...phase,
                guid: Utils.newGuid()
            }
        });
        this.form.setValue('phases', []);
        this.form.setValue('phases', phases);
    }

    public buildPhasesByDates(responsiblesDSI: ProjectResponsibleDsiViewModel[], unapplySetValues: boolean) {
        const formStartDate = this.form.getValues('startDate');
        const formEndDate = this.form.getValues('endDate');
        const oldPhases = this.form.getValues('phases');
        const DSIResponsibles = this.form.getValues('responsiblesDsi');

        const newDates: Date[] = Utils.getYearsInRange(formStartDate, formEndDate);

        let responsiblesDSIRemoved: string[] = [];

        const phases = oldPhases.map(phase => {

            const dates: ProjectPhaseDateViewModel[] = newDates.map(date => {
                const oldPhaseDate = phase.dates.find(d => d.date.getFullYear() === date.getFullYear())
                const checkedMonths = (oldPhaseDate?.periodsItems || []).filter(y => y.checked).map(y => y.date);
                const yearMonths = Utils.getMonthsInYear(date);

                let provisional: ProjectPhaseDatePeriodProvisionalScheduleViewModel[];
                if (oldPhaseDate?.periodsItems) {
                    provisional = oldPhaseDate?.periodsItems.map(x => x.provisionalSchedules ?? []).flat();
                }

                const periodsItems: ProjectPhaseDatePeriodItemViewModel[] = yearMonths.map(yearMonth => {

                    const checked = checkedMonths.find(x => x.getMonth() === yearMonth.getMonth()) ? true : false;

                    let schedules: ProjectPhaseDatePeriodProvisionalScheduleViewModel[] = [];
                    if (checked) {
                        schedules = provisional.filter(p => {
                            const provisionalDate = new Date(p.date).toLocaleDateString([], { year: 'numeric', month: '2-digit' });
                            const yearMonthDate = new Date(yearMonth).toLocaleDateString([], { year: 'numeric', month: '2-digit' });
                            return (provisionalDate == yearMonthDate && !!DSIResponsibles.find(a => a.projectResponsibleDsiId == p.projectResponsibleDsiId))
                        })

                        schedules = [
                            ...schedules,
                            ...DSIResponsibles.filter(x => !schedules.find(a => a.projectResponsibleDsiId == x.projectResponsibleDsiId)).map(dsi => {
                                return {
                                    projectResponsibleDsiId: dsi.projectResponsibleDsiId,
                                    projectResponsibleDsiName: responsiblesDSI.find(x => x.id == dsi.projectResponsibleDsiId)?.name ?? '',
                                    date: new Date(yearMonth),
                                    estimatedTime: undefined
                                };
                            }),
                        ];

                        responsiblesDSIRemoved = [
                            ...responsiblesDSIRemoved,
                            ...provisional.filter(x => !schedules.find(s => s.projectResponsibleDsiId == x.projectResponsibleDsiId) &&
                                !responsiblesDSIRemoved.find(s => s == x.projectResponsibleDsiId)
                                && x.estimatedTime != null
                            ).map(x => x.projectResponsibleDsiId)
                        ];

                    } else {
                        schedules = [
                            ...schedules,
                            ...DSIResponsibles.filter(x => !schedules.find(a => a.projectResponsibleDsiId == x.projectResponsibleDsiId)).map(dsi => {
                                return {
                                    projectResponsibleDsiId: dsi.projectResponsibleDsiId,
                                    projectResponsibleDsiName: responsiblesDSI.find(x => x.id == dsi.projectResponsibleDsiId)?.name ?? '',
                                    date: yearMonth,
                                    estimatedTime: undefined
                                };
                            }),
                        ];
                    }

                    return {
                        checked: checked,
                        date: yearMonth,
                        provisionalSchedules: schedules.sort((a, b) => (a.projectResponsibleDsiName > b.projectResponsibleDsiName) ? 1 : -1)
                    };
                });

                return {
                    dateFormat: oldPhaseDate?.dateFormat || 'YYYY',
                    dateMode: oldPhaseDate?.dateMode || DateMode.YEAR,
                    date,
                    periods: [],
                    periodsItems,
                };
            });

            return {
                ...phase,
                dates
            }
        });

        if (!unapplySetValues) {
            this.form.setValue('phases', []);
            this.form.setValue('phases', phases);
        }

        return responsiblesDSIRemoved;
    }


    public buildScores(project: ProjectViewModel | null, scores: ScoreViewModel[], types: ScoreType[]) {

        const list: ProjectScoreViewModel[] = [];
        
        types.map(t => {
            scores.map(s => {
                const exist = (project?.scores || []).find(x => x.scoreId == s.id && x.type == t);
                if(exist){
                    if(exist.active || (!exist.active && exist.value > 0)){
                        list.push(exist); 
                    }
                } else {
                    if(s.active){
                        list.push({
                            scoreId: s.id ?? '',
                            name: s.name,
                            active: s.active,
                            value: 0,
                            isNew: true,
                            type: t
                        });
                    }
                }
            })
        })

        this.form.setValue('scores', list);
    }

    public buildQualityQuestions(project: ProjectViewModel | null, qualityQuestions: QualityQuestionViewModel[]) {

        let list: ProjectQualityQuestionViewModel[] = (project?.qualityQuestions || []).map(x => {
            return {
                qualityQuestionId: x.qualityQuestionId,
                name: x.name,
                active: x.active,
                value: x.value,
            }
        });

        list = [
            ...list,
            ...qualityQuestions.filter(x => !list.find(i => i.qualityQuestionId == x.id)).map(x => {
                return {
                    qualityQuestionId: x.id ?? '',
                    name: x.name,
                    active: x.active,
                    value: 0,
                    isNew: true
                }
            })
        ];

        this.form.setValue('qualityQuestions', list);
    }

    public buildConcernedPartners(partners: PartnersViewModel[], project: ProjectViewModel | null) {

        const formConcernedPartners = partners.map(cp => {
            const cpInModel = project?.concernedPartners.find(x => x.partnerId === cp.id);
            return {
                partnerId: cp.id,
                name: cp.name,
                active: cp.active,
                checked: cpInModel ? true : false,
            };
        });

        project?.concernedPartners.filter(x => !formConcernedPartners.find(f => f.partnerId == x.partnerId)).forEach(x => {
            formConcernedPartners.push({
                partnerId: x.partnerId,
                name: x.name,
                active: x.active,
                checked: true,
            })
        });

        this.form.setValue('concernedPartners', formConcernedPartners);
    }

}

export default ProjectScreenFormBuilder;