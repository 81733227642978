import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { CriticalitiesSearchCriteria } from './models/CriticalitiesSearchCriteria';
import { CriticalityViewModel } from './models/CriticalityViewModel';

class CriticalitiesService {
    
    public getList(criteria: CriticalitiesSearchCriteria) {
        return Http.get<Page<CriticalityViewModel>>('criticalities', criteria);
    }

    public getCriticalityById(id: string) {
        return Http.get<CriticalityViewModel>('criticalities/'+ id);
    }

    public save(model: CriticalityViewModel) {
        return !!model.id ? Http.put('criticalities', model) : Http.post('criticalities', model);
    }

    public remove(model: CriticalityViewModel) {
        return  Http.put('criticalities/deactivate', model);
    }
}

export default new CriticalitiesService();