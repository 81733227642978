/**
 * Version
 */
const version = process.env.REACT_APP_VERSION;
export const APP_VERSION = version?.endsWith('.0') ? version.slice(0, - 2) : version;

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';

/**
 * API
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER: 'AUTH_USER',
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}
export enum LOGGER_LOG_TYPE {
    REQUEST,
    APP_CRASH,
}
export const LOGGER_PRINT_LOGS = process.env.REACT_APP_LOGGER_PRINT_LOGS || false;
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;


/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAUT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';
export const DATE_FORMAT_YEAR = 'YYYY';
export const DATE_FORMAT_MONTH_YEAR_2 = 'MMMM, YYYY';
export const DATE_TIME_FILTER_FORMAT = 'dd/MM/yyyy HH:mm';


/**
 * NUMBERS
 */
export const NUMBERS_MAX_LENGTH = 14;
export const NUMBERS_MAX_VALUE = 999999999.99;


/**
 * POLICIES
 */
export const POLICIES = {
    PROJECT_READ: 'SETTINGUP_PROJECT_READ',
    PROJECT_WRITE: 'SETTINGUP_PROJECT_WRITE',
    PARAMETERS_READ: 'SETTINGUP_PARAMETERS_READ',
    PARAMETERS_WRITE: 'SETTINGUP_PARAMETERS_WRITE',
    GROUP_WRITE: 'SETTINGUP_GROUP_WRITE',
    STATUS_WRITE: 'SETTINGUP_STATUS_WRITE',
    COLLABORATORS_TIMES_WRITE: 'SETTINGUP_COLLABORATORS_TIMES_WRITE',
    SETTINGUP_PROJECT_REMOVE_WRITE: 'SETTINGUP_PROJECT_REMOVE_WRITE',
    COLLABORATORS_TIMES_READ: 'SETTINGUP_COLLABORATORS_TIMES_READ',
    SETTINGUP_RECEIVE_EMAIL_PROJECT_CHANGES_READ: 'SETTINGUP_RECEIVE_EMAIL_PROJECT_CHANGES_READ',
    SETTINGUP_LOGIN_LOGS_READ: 'SETTINGUP_LOGIN_LOGS_READ'
};

/**
 * ROLES
 */
export const ROLES = {
    ADMINISTRATOR: 'ADMINISTRATOR',
    ADMINISTRATOR_GROUP: 'ADMINISTRATOR_GROUP'
};

/**
 * LISTING PAGES
 */
export const SEARCH_HISTORY_PAGES = {
    LIST_PROJECTS: 'LIST_PROJECTS',
    PROJECTS_ADVANCEMENTS_DASBOARD : 'PROJECTS_ADVANCEMENTS_DASBOARD',
    PROJECTS_TIMES : 'PROJECTS_TIMES',
    COLLABORATORS_TIMES : 'COLLABORATORS_TIMES',
}

export const NAVBAR_ID = 'navbarContainer';

export const GROUP_PARAMS_TYPE = {
    DETAILS: 'DETAILS',
    PACKS: 'PACKS',
    POLICIES: 'POLICIES',
    ROLES: 'ROLES',
    USERS: 'USERS',
    LDAPS: 'LDAPS',
    STRATEGIC_AXES: 'STRATEGIC_AXES',
    DEPARTMENTS: 'DEPARTMENTS',
    DOMAIN_PROJECTS: 'DOMAIN_PROJECTS',
    ESTABLISHMENTS: 'ESTABLISHMENTS',
    PRIORITY_LEVELS: 'PRIORITY_LEVELS',
    CRITICALITIES: 'CRITICALITIES',
    PARTNERS: 'PARTNERS',
    PRODUCTS: 'PRODUCTS',
    QUALITY_QUESTIONS: 'QUALITY_QUESTIONS',
    PROJECT_MANAGERS: 'PROJECT_MANAGERS',
    SOURCES: 'SOURCES',
    PROJECT_RESPONSIBLES_DSI: 'PROJECT_RESPONSIBLES_DSI',
    PROJECT_RESPONSIBLES: 'PROJECT_RESPONSIBLES',
    STATUS: 'STATUS',
    TYPOLOGIES: 'TYPOLOGIES',
    SUCCESS_INDICATORS: 'SUCCESS_INDICATORS',
    EVALUATION_METHODOLOGIES: 'EVALUATION_METHODOLOGIES',
    SCORES: 'SCORES'
};
        
export const LOGIN_LOG_TYPE = {
    SUCCESS: 'SUCCESS',
    WRONG_PASSWORD: 'WRONG_PASSWORD',
    WRONG_USER: 'WRONG_USER',
    LDAP_SUCCESS: 'LDAP_SUCCESS',
    LDAP_ERROR: 'LDAP_ERROR',
};